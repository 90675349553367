import React from 'react';

import Beverage from 'components/Menu/Beverage';
import Jumbotron from 'components/misc/Jumbotron';
import TabBar from 'components/misc/TabBar';

const tabBarMenus = [
  { name: '베이커리', link: '/menu/bakery/' },
  { name: '음료', link: '/menu/beverage/' },
  { name: '디저트', link: '/menu/dessert/' },
  { name: '푸드', link: '/menu/food/' },
  { name: '상품', link: '/menu/retail/' },
];

const BeveragePage = () => (
  <div>
    <Jumbotron
      engTitle="Beverage"
      korTitle="음료"
      image={require('../../assets/images/banner/menu-beverage.jpg')}
      content={
        <span>
          아티제는 최고급 스페셜티 커피는 물론 다양한 시즌 음료들을 선보이고 있습니다.
          <br />
          엄선된 좋은 재료만을 사용해 신선함이 살아있는
          <br />
          스페셜한 음료들을 만나보세요.
        </span>
      }
    />
    <TabBar index={1} data={tabBarMenus} />
    <Beverage />
  </div>
);

export default BeveragePage;
