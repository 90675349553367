import React from 'react';
import classNames from 'classnames/bind';
import Swiper from 'react-id-swiper';
import { Container, Row, Col, ScreenClassRender } from 'react-grid-system';
import ContainerDimensions from 'react-container-dimensions';

import styles from './Beverage.module.scss';
import MenuItemDesc from './common/MenuItemDesc';
import MenuSetLeft from './common/MenuSetLeft';
import MenuSetRight from './common/MenuSetRight';
import Divider from './common/Divider';
import menus from '../../datas/salemenu/beverage.js';
import { params5 } from './common/swiperParam';

const cx = classNames.bind(styles);
const { coffees, realFruits, teas, others } = menus;

const Beverage = () => (
  <div>
    {/* 커피 */}
    <MenuSetLeft
      engTitle="Coffee"
      korTitle="커피"
      image={require('../../assets/images/menu/beverage/coffee/big.png')}
      content={<span>살구의 은은한 산미와 견과류의 고소함이 느껴지는 부드럽고 깔끔한 아티제 시그니처 블렌드</span>}>
      <ScreenClassRender
        render={screenClass => (
          <div className={cx(['illust', 'clip1', { mobile: ['xs', 'sm', 'md'].includes(screenClass) }])} />
        )}
      />
    </MenuSetLeft>
    <div className={cx('swiper')}>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <ContainerDimensions>
              {({ width }) => {
                return (
                  <Swiper {...params5}>
                    {coffees.map((coffee, i) => {
                      return (
                        <div key={i} className={cx('item')}>
                          <MenuItemDesc item={coffee} />
                        </div>
                      );
                    })}
                  </Swiper>
                );
              }}
            </ContainerDimensions>
          </Col>
        </Row>
      </Container>
    </div>

    <Divider />

    {/* 과일음료 */}
    <MenuSetRight
      engTitle="Real fruit beverage"
      korTitle="과일음료"
      image={require('../../assets/images/menu/beverage/fruit/big.png')}
      content={
        <span>
          매일 아침 직접 짜서 더 상큼한 과즙,
          <br />
          신선한 과일을 그대로 담아드리는 100% real fruit
        </span>
      }>
      <ScreenClassRender
        render={screenClass => (
          <div className={cx(['illust', 'clip2', { mobile: ['xs', 'sm', 'md'].includes(screenClass) }])} />
        )}
      />
    </MenuSetRight>
    <div className={cx('swiper')}>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <ContainerDimensions>
              {({ width }) => {
                return (
                  <Swiper {...params5}>
                    {realFruits.map((realFruit, i) => {
                      return (
                        <div key={i} className={cx('item')}>
                          <MenuItemDesc item={realFruit} />
                        </div>
                      );
                    })}
                  </Swiper>
                );
              }}
            </ContainerDimensions>
          </Col>
        </Row>
      </Container>
    </div>

    <Divider />

    {/* 차 */}
    <MenuSetLeft
      engTitle="Tea"
      korTitle="차"
      image={require('../../assets/images/menu/beverage/tea/big.png')}
      content={<span>고급 차 잎만 엄선하여, 차별화된 풍부한 향을 선사하는, 스티븐 스미스 티메이커</span>}>
      <ScreenClassRender
        render={screenClass => (
          <div className={cx(['illust', 'clip3', { mobile: ['xs', 'sm', 'md'].includes(screenClass) }])} />
        )}
      />
    </MenuSetLeft>
    <div className={cx('swiper')}>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <ContainerDimensions>
              {({ width }) => {
                return (
                  <Swiper {...params5}>
                    {teas.map((tea, i) => {
                      return (
                        <div key={i} className={cx('item')}>
                          <MenuItemDesc item={tea} />
                        </div>
                      );
                    })}
                  </Swiper>
                );
              }}
            </ContainerDimensions>
          </Col>
        </Row>
      </Container>
    </div>

    <Divider />

    {/* 기타 */}
    <MenuSetRight
      engTitle="Others"
      korTitle="기타"
      image={require('../../assets/images/menu/beverage/others/big.png')}
      content={<span>다양한 재료로 선보이는 아티제만의 블렌디드 음료</span>}>
      <ScreenClassRender
        render={screenClass => (
          <div className={cx(['illust', 'clip4', { mobile: ['xs', 'sm', 'md'].includes(screenClass) }])} />
        )}
      />
    </MenuSetRight>
    <div className={cx('swiper')}>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <ContainerDimensions>
              {({ width }) => {
                return (
                  <Swiper {...params5}>
                    {others.map((other, i) => {
                      return (
                        <div key={i} className={cx('item')}>
                          <MenuItemDesc item={other} />
                        </div>
                      );
                    })}
                  </Swiper>
                );
              }}
            </ContainerDimensions>
          </Col>
        </Row>
      </Container>
    </div>
  </div>
);

export default Beverage;
